:root {
  --color-background: #0f0f14;
  --color-text: #fff;
  --color-header: #1a1a22;
  // --color-header: #1e1e28;
  --color-header2: hsl(240, 2%, 20%);
  --color-button: #009270;
}

[data-theme="green"] {
  --color-button: #009270;
}

[data-theme="orange"] {
  --color-button: rgb(246, 72, 9);
}

[data-theme="yellow"] {
  --color-button:rgb(161, 141, 24);
}

[data-theme="red"] {
  --color-button:rgb(243, 28, 28);
}

[data-theme="pink"] {
  --color-button:rgb(228, 10, 155);
}

[data-theme="blue"] {
  --color-button:#4d57a6;
}


.dark {


  .layout {
    background-color: var(--color-background);
  }

  .header {
    background-color: var(--color-header);
  }

  .header svg {
    color: var(--color-button);
  }

  .header b {
    color: var(--color-button) !important;
  }

  .layout button {
    background-color: var(--color-button) !important;
  }

  .instructionPage {
    color: var(--color-text);
  }

  .instructionPage h3 {
    color: var(--color-button);
  }

  .home-dashboard {
    background-color: var(--color-background);
  }

  .home-option-card img {
    border-color: var(--color-button);
  }

  .casino_box img {
    border-color: var(--color-button) !important;
  }

  .teenpatti-header {
    background-color: var(--color-header) !important;
    color: var(--color-text);
  }

  .place-bet-h {
    background-color: var(--color-header) !important;
  }

  .select_bet_box {
    background-color: var(--color-header);
    color: var(--color-text) !important;
  }

  .card-text {
    color: var(--color-text) !important;
  }

  .bet-button {
    background: var(--color-button);
  }

  .teenpatti-betsection table td {
    background-color: var(--color-background);
    color: white;
  }

  .bet-result span {
    background-color: var(--color-header2) !important;
  }

  .last_result {
    background-color: var(--color-header) !important;
  }

  .footer {
    background-color: var(--color-header) !important;
  }



  .teenpatti .box-6 {
    background-color: var(--color-header) !important;
    color: white;
  }

  .teenpatti tbody td {
    border-color: var(--color-header2) !important;
  }

  .teenpatti .box-4 {
    background-color: var(--color-button) !important;
    color: var(--color-text);
  }

  .teenpatti-betsection th {
    background-color: var(--color-header2);
    color: var(--color-text);
  }


  .notification-header {
    background-color: var(--color-background);
  }

  .teenpatti-betlist-container .table {
    border-color: var(--color-header2);
    border-radius: 6px;
  }

  .teenpatti-betlist-container .table thead th {
    background-color: var(--color-header);
    color: var(--color-text);
  }

  .teenpatti-betlist-container .table tbody td {
    background-color: var(--color-background);
    color: var(--color-text);
  }

  .placebet-header{
    background-color: var(--color-button) !important;
    color: var(--color-text) !important;
  }
}