.scorecard .sr-lmt-plus__segment {
    display: none;
}


.scorecard .sr-simcrick-mom {
    display: none;
}

.scorecard .sr-tabs__flexcontainer {
    display: none;
}

.scorecard .sr-simcrick__comp-wrap {
    width: 100%;
}

.scorecard .srm-notLmt {
    display: none;
}

.scorecard .sr-simcrick-pitch__bg {
    height: 150px;
}

.scorecard .sr-simcrick__cmp-inner {
    height: 200px;
}




.live_stream p {
    color: #ffe307;
    font-size: 11px;
    margin: 0px;
    font-weight: bold;
}

.live_stream {
    position: relative;
}

.overflow-card-section {
    position: absolute;
    top: 5px;
    left: 6px;
    font-size: 12px;
}

.overflow-card-section img {
    width: 35px;
    height: 45px;
    padding: 2px;
}


@media (max-width: 768px) {
    .overflow-card-section img {
        width: 22px;
        height: 27px;
        padding: 2px;
    }
}