.casino_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0px;
  justify-content: center;
}

.img-container .casino_box {
  position: relative;
}

.casino_box img {
  width: 100%;
  height: auto;
  border: 2px solid rgb(2, 91, 150);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  object-fit: cover;
  border-radius: 6px;
  /* Ensure the image covers the container without stretching */
}



.main-casion-container {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-weight: 600;
}



@media (max-width: 600px) {

  /* Add your CSS for small screens here */
  .main-casion-container .casino-text {
    display: none;
  }
}

@media (min-width: 800px) {
  /* Add your CSS for large screens here */

  .casino_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
    margin: 0px;
    justify-content: center;
  }


  .casino_box img {
    width: 100%;
    height: auto;
    border: 2px solid rgb(2, 91, 150);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    object-fit: cover;
    /* Ensure the image covers the container without stretching */
  }

}







/* .overlap-casino_box {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 50%;  
  background-color: rgba(175, 167, 156, 0.719);
    color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center; 
   font-size: 20px; 
   font-weight: bold;
  z-index: 99;
 }  */




/* akbar lottery */

.akbar_header {
  width: 100%;
  height: 50px;
  align-items: center;
  color: white;
  justify-content: center;
  display: flex;
  font-size: 25px;
  font-weight: 600;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  background-color: #574f55;
}

.mini-button {
  padding: 10px;
  margin: 2px;
}





.tc {
  text-align: center;
}

.wt {
  color: white;
}

.live_stream {
  width: 100%;
  height: 263px;
  display: flex;
  background-color: #000;
  justify-content: center;
  align-items: center;
}

.bet_box_true {
  text-align: center;
  padding: 10px, 2px;
  background-color: #a0bfee;
}

.bet_box_false {
  text-align: center;
  padding: 10px, 2px;
  background-color: #eea0a0;
}

.bet_box_even {
  text-align: center;
  padding: 10px, 2px;
  font-weight: bold;
  color: white;
  background: #3a6186;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #89253e, #3a6186);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #89253e, #3a6186);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


}

.bet_box_odd {
  text-align: center;
  padding: 10px, 2px;
  font-weight: bold;
  color: white;
  background: #159957;
  background: -webkit-linear-gradient(to right, #155799, #159957);
  background: linear-gradient(to right, #155799, #159957);
}



.select_bet_box {
  text-align: center;
  background-color: #ffffff;
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-bottom: 0.75rem;
}

.last_result {
  background-color: #343a40;
  color: white;
  font-size: 14px;
  margin-bottom: 0.1rem;
  border-radius: 6px;
  padding: 0.5rem 1rem;
}


.teenpatti .box-6 {
  width: 60%;
  background-color: #b5c7ce;
  padding: 0px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.teenpatti .box-4 {
  width: 40%;
  background-color: #b0b4ef;
  padding: 0px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.teenpatti table {
  width: 100%;
  background-color: #bfd7fa;
  margin-top: 1rem;
  border-radius: 6px;
}

.teenpatti tbody td {
  padding: 8px;
  font-weight: 500;
  border: 2px solid rgb(255, 255, 255);
  font-size: 18px;

}

.teenpatti-betsection {
  overflow: hidden;
  border-radius: 6px;
}

.teenpatti-betsection table {
  border-radius: 6px;
}

.teenpatti-betsection .betting-rule {

  border-radius: 10px;
  padding: 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.teenpatti-betsection .place-bet {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.betting-rule table tbody,
tr {
  border: 2px solid #e6e6e6;
  border-width: 1px;
}

.bet-result {
  text-align: end;
  overflow-x: auto;
}


.bet-result span {
  background-color: #209154;
  color: #ffffff;
  padding: 5px 9px;
  font-size: 10px;
  margin: 0px 2px;
  border-radius: 50px;

}

.place-bet-h {
  width: 100%;
  color: white;
  background-color: #009272;
  font-weight: bold;
  margin-bottom: 1rem;
  border-radius: 6px;
  padding: 0.5rem 1rem;
}

.bg-blue {
  background-color: #5e718f;
}

.t-white {
  color: white
}

.t-14 {
  font-size: 14px;
  font-weight: 500;
}

.t-16 {
  font-size: 16px;
  font-weight: 500;
}


.card-image {
  height: 42px;
  display: block;
}

.card-text {
  margin-top: 8px;
  /* Space between image and text */
  font-size: 16px;
  /* Font size for the text */
  color: #000;
  /* Text color */
}

#myDIV {
  height: 300px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 10px;
  background-color: red;
  padding: 10px;
  grid: 100px / auto auto auto;
}


.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  gap: 2px;
  width: 100%;
}



.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.luckeybet-sign-button img {
  width: 16px;
  height: 16px;
}

.casino_container {
  /* background-color: #6c757d; */
}

.amarakbar button {
  border: none;
}

.luckey-seven button {
  border: none;
}

.dragon-tiger button {
  border: none;
}


.game-bet-dialog {
  background: #114357;
  background: -webkit-linear-gradient(to left, #F29492, #114357);
  background: linear-gradient(to left, #F29492, #114357);
}

.teenpatti table tbody tr {
  border-width: 1px;
  border-color: none;
}

.btn-group button {
  border: 2px solid #ececec;
}




/* Make images take half the width of the device */
.image-section {
  display: flex;
  justify-content: space-between;
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  /* Each image takes half the width */

}

.image-container {
  width: 100%;
  height: 400px;
  background-color: violet;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.img-responsive {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn-lottery {
  width: 100%;
  padding: 10px;
  background: #FF512F;
  background: -webkit-linear-gradient(to right, #DD2476, #FF512F);
  background: linear-gradient(to right, #DD2476, #FF512F);

  color: white;
  border: none;
  cursor: pointer;
}


@media (max-width: 768px) {
  .image-container {
    height: 250px;
  }

  .btn-lottery {
    font-size: 14px;
  }
}

.instructionPage ul {
  list-style: none;
  padding: 0px;
}


.instructionPage ul li {
  font-size: 10px;
}

.instructionPage p {
  font-size: 10px;
}

.instructionPage h3 {
  font-size: 20px;
}


.overflow-teenpatti {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.overflow-dragon-tiger-btn1 {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  transition-property: all;
  top: 50%;
  /* Adjust to center vertically */
  left: 50%;
  /* Adjust to center horizontally */
  background-color: rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -50%);
  /* Center the lock icon perfectly */
  z-index: 10;
  /* Ensure it appears above the button */
  display: flex;
  justify-content: center;
  align-items: center;
}

.overflow-dragon-tiger-card {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-property: all;
  height: 100%;
  text-align: center;
  top: 50%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
  z-index: 10;
}

.overflow-aaa-btn1 {
  position: absolute;
  width: 90%;
  transition-property: all;
  top: 50%;
  /* Adjust to center vertically */
  left: 50%;
  /* Adjust to center horizontally */
  background-color: rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
  /* Center the lock icon perfectly */
  z-index: 10;
  /* Ensure it appears above the button */
}




.casinoifram iframe {
  min-height: 206px;
  /* Default for mobile screens */
}

/* For larger screens (you can adjust the pixel value as needed) */
@media (min-width: 768px) {

  /* Example: Tablets and larger screens */
  .casinoifram iframe {
    min-height: 351px;
  }

  .live_stream {
    width: 100%;
    height: 360px;
    display: flex;
    background-color: #000;
    justify-content: center;
    align-items: center;
  }

}

.teenpatti-header {
  background-color: #343a40;
  color: #fff;
  margin: 10px 0px;
  font-size: 12px;
  border-radius: 6px;
  padding: 0.5rem 1rem;
}

.andarbaharscroll {
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  max-width: 100%;
  /* Enable horizontal scrolling */
  white-space: nowrap;
  /* Ensure items don't wrap to the next line */
  max-width: 250px;
  /* Set a fixed width for the container */
  scrollbar-width: none;
  /* For Firefox, hide scrollbar */
}

.andarbaharscroll::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Chrome, Safari, and Opera */
}

.andarbaharscroll div {
  flex: 0 0 auto
}

.css-ypiqx9-MuiDialogContent-root {
  padding: 0px;
}


/* 
background-color: #000000;
width: 100%;
height: 311px;
display: flex;
justify-content: center;
align-items: center; */


.main-websocket {
  width: 100%;
}



.min-max{
  display: table-footer-group;
  font-size: 14px;
  font-weight: 500;
  color: #5a5a5a;
}












/* // dragon css  */
.teenpatti-betlist-container {
  overflow-x: auto;
  border-radius: 10px;
  padding: 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}


.bet-button-overlay {
  position: relative;
  width: 100%;
}

.bet-button {
  width: 100%;
  height: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px;

  text-align: center;
  font-weight: 700;
  padding: 0.4rem 1rem;
  border-radius: 4px;
  color: white;
  /* 
  background: rgb(2, 0, 36);
  background: linear-gradient(9deg, rgba(2, 0, 36, 1) 0%, rgba(23, 107, 148, 1) 50%, rgba(0, 212, 255, 1) 100%);
   */
  background: #3a6186;
  background: -webkit-linear-gradient(to left, #89253e, #3a6186);
  background: linear-gradient(to left, #89253e, #3a6186);

}



/* statement */



.headerSearchItem {
  position: relative;
  /* z-index: 999999; */
  border: 1px solid #009270;
  border-radius: 4px;
  padding: 0.2rem 0.5rem;
}
.headerSearchText{
  padding: 0 0.5rem;
}

.tableTool-button {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}