.flip-countdown {
  display: inline-block;
  perspective: 1000px;
}


.flip-card {
  width: 26px;
  height: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: top;
  transform-style: preserve-3d;
  transition: transform 0.5s ease-in-out;
}



.flip-card.flipping {
  transform: rotateX(-90deg);
}

.card-face {
  width: 80%;
  height: 100%;
  background-color: #bababaa2;
  color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  /* For older Safari and Chrome versions */
  -moz-backface-visibility: hidden;
  /* For older Firefox versions */
}





.top,
.bottom,
.bottom-flip {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #292929;
  color: #ccc;
  border-radius: 0px;
  font-weight: 500;
}

.top {
  top: 0;
  border-bottom: 1px solid #222;
}

.bottom {
  bottom: 0;
  border-top: 1px solid #222;
  top: 0;
}

.bottom-flip {
  bottom: 0;
  transform-origin: top;
  transform: rotateX(90deg);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  /* For older Safari and Chrome versions */
  -moz-backface-visibility: hidden;
  /* For older Firefox versions */
}

.bottom.flipping {
  animation: flip-down 0.6s forwards;
}

.bottom-flip.flipping {
  animation: flip-up 0.6s forwards;
  background-color: red;
  visibility: hidden;
}

@keyframes flip-down {
  0% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(-90deg);
  }
}

@keyframes flip-up {
  0% {
    transform: rotateX(90deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

.card-line {
  border: 1px solid #000000;
  /* Removes the default border */
  width: 100%;
  /* Full width of the container */
  margin: 0;
  /* Remove default margin */
  position: absolute;
  top: 15px !important;
  margin: 0;
  z-index: 60;
}

.overflow-casino-timer {
  position: absolute;
  left: 85%;
  bottom: 1%;
}

@media (min-width: 768px) {

  /* Example: Tablets and larger screens */
  .overflow-casino-timer {
    position: absolute;
    left: 90%;
    top: 68%;
  }

  .card-line {
    border: 1px solid #000;
    margin: 0;
    position: absolute;
    top: 28px !important;
    width: 100%;
    z-index: 60;
  }

  .flip-card {
    align-items: center;
    display: flex;
    height: 54px;
    justify-content: center;
    position: relative;
    transform-origin: top;
    transform-style: preserve-3d;
    transition: transform .5s ease-in-out;
    width: 42px;
  }

  .top,
  .bottom,
  .bottom-flip {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #292929;
    color: #ccc;
    border-radius: 0px;
    font-weight: 500;
  }


}

@media (min-width: 600px) {

  /* Example: Tablets and larger screens */
  .overflow-casino-timer {
    position: absolute;
    left: 91%;
    top: 50%;
  }
}

@media (min-width: 500px) {

  /* Example: Tablets and larger screens */
  .overflow-casino-timer {
    position: absolute;
    left: 83%;
    top: 72%;
  }
}











/* font-size 29 */