.session-grid {

    //header
    .session-header {
        margin: 0 0;
        display: grid;
        grid-template-columns: 70% 30%; // Define column sizes
        gap: 1px; // Add a gap between the columns to simulate border collapse
        border: 1px solid rgb(255, 255, 255);

        .session-header-left {
            background-color: #cda9c7;
            padding: 0.5rem;
            border-right: 1px solid rgb(255, 255, 255);
        }

        .session-header-right {
            background-color: #cda9c7;
            position: relative;
            display: grid;
            grid-template-columns: 50% 50%;
            gap: 1px;
            border-right: 1px solid rgb(255, 255, 255);
            text-align: center;

            div {
                padding: 0.5rem;
            }
        }
    }

    // body
    .session-body {
        margin: 0rem 0;
        display: grid;
        grid-template-columns: 70% 30%; // Define column sizes
        gap: 1px; // Add a gap between the columns to simulate border collapse
        border: 1px solid rgb(255, 255, 255);

        .session-body-left {
            background-color: #e9e9e9;
            padding-left: 8px;
            border-right: 1px solid rgb(255, 255, 255);
        }

        .session-body-right .back-section {

            // background-color: #87cefa;  
            background-color: #e9e9e9;
            color: blue;

        }

        .session-body-right .lay-section {

            // background-color: #f08080;
            background-color: #e9e9e9;
            color:red;
        }


        .session-body-right {

            position: relative;
            display: grid;
            grid-template-columns: 50% 50%;
            gap: 1px;
            border-right: 1px solid rgb(255, 255, 255);
            text-align: center;

            div {}

            .overlap-content {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                text-align: center;
                font-weight: bold;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(175, 167, 156, 0.719);
                color: rgb(255, 0, 0);
                z-index: 99;
            }
        }

        .h-tx {
            font-size: 12px;
            font-weight: 600;
        }
        .h-tx1 {
            font-size: 13px;
            font-weight: 500;
        }

        .l-tx {
            font-size: 10px;
        }
    }




    .bet-section .enterbetAmount button {
        border-radius: 5px;
        border: 1px solid black;
        color: white;
        font-size: 10px;
        padding: 6px 3px;
        letter-spacing: 0px;

    }


    .button-group {
        width: 100%;
    }

    .button-group .MuiButtonGroup-root {
        width: 100%;
    }

    .button-group .MuiButtonGroup-grouped {
        width: 100%;
        flex: 1;
    }

    .button-group .MuiButton-root {
        width: 100%;
        flex: 1;
    }

}