a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
}

th{
  white-space: nowrap !important;
  padding: 8px !important;
}
td{
  white-space: nowrap !important;
  padding: 8px !important;
  font-size: 13px !important;
}

p {
  padding: 0;
  margin: 0 !important;
}

button {
  background-color: var(--color-button) !important;
}


.layout {
  height: calc(100vh - 150px);
  padding: 2rem;
  overflow: hidden;
  overflow-y: auto;
}

.loginPage {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;

}

.logintext {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: end;
  font-size: 21px;
  color: #4a4a4a;
}


.loginBox {
  max-width: 500px;
  margin: auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
  background-color: white;
}

.cmd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cmc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cme {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}



.img-field img {
  width: 40px;
  height: 40px;
}

.header {
  padding: 0px 10px;
  color: white;
  font-size: 22px;
  height: 70px;
  background-color: #009270;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

}


.home-option-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  overflow: hidden;
}

.scorecard{
  position: sticky;
  top: -30px!important;
  z-index: 10;
} 

.home-option-card img {
  width: 100%;
  height: auto;
  border: 2px solid rgb(2, 91, 150);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  object-fit: cover;
  border-radius: 6px;
  /* Ensure the image covers the container without stretching */
}



@media (min-width: 750px) {

  /* Add your CSS for large screens here */
  .home-option-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    overflow: hidden;
  }

  .home-option-card img {
    width: 100%;
    height: auto;
    border: 2px solid rgb(2, 91, 150);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    object-fit: cover;
    /* Ensure the image covers the container without stretching */
  }

}

.Mobview {
  display: none;
}

.statementDesktopview {
  display: inline;
}

@media only screen and (max-width: 768px) {
  .scoresection {
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 10px;
  }

  .Desktopview {
    display: none !important;
  }

  .Mobview {
    display: inline;
  }


  .scoresection .detail-sec {
    width: 100%;
    text-align: center;
  }

}

@media only screen and (max-width: 480px) {
  .layout {
    padding: 0.5rem 0.5rem;
  }



}

.footer {
  height: 30px;
  color: rgb(255, 255, 255);
  background-color: #4a4a4a;
}

.footer span {
  font-size: 14px;
}

.notify_ {
  height: 60;
  color: white;
  background-color: rgb(0, 0, 0);

}

.back_button {
  background-color: rgb(124, 171, 253);
  color: white;
  height: 60px;

}

.inplaycardDetails {
  list-style: none;
  padding: 0px;
}

.inplaycardDetails li {
  font-size: 18px;
  font-weight: 500;
  padding: 0px;
  color: #657dff;
}

.card-body {
  padding: 0px !important;
}

hr {
  margin: 0px !important;
}

.header-contant {
  display: grid;
  margin-left: '-14px'
}

.header-contant span {
  padding: 0px;
  font-weight: 500;
}


.sassion-table table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  text-align: left;
}

.sassion-bet-table td {
  border: 2px solid;
  border-width: 1px;
}

.sassion-bet-table tr th {
  padding: 0px 10px;
  background-color: #cda9c7;
  text-align: center !important;
  font-size: 14px;
}

.sassion-table th {
  padding: 12px;
  background-color: #cda9c7;
  text-align: center !important;
  font-size: 14px;
}


body {
  font-family: Arial, sans-serif;
}

table {
  width: 100%;
}

.sassion-table {
  width: 100%;
  margin: 0 auto;
  overflow-x: auto;

}

.bet-section {
  background: #ffffff;
  padding: 10px 5px;
  border-radius: 10px 10px 0px 0px;
}

.bet-section h3 span {
  color: #41393f;
}



.bet-section .enterbetAmount input {
  padding: 0.5rem 1rem;
  border: 1px solid rgb(0 0 0);
  background-color: #f9c3f0;
  height: 35px;
  border: 1px solid #000;
}



.scoresection img {
  width: 60px;
}

.scoresection {
  font-size: 20px;

}

.img-sec {
  padding-right: 15px;
}


.scoresection .img-sec img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}

.placebet-header {
  background-color: var(--color-button) !important;
  color: var(--color-text) !important;
}


.tv-section {
  align-items: center;
  height: 35px;
  background-color: #9b6191;
}


.profile-page {
  padding: 20px;
  max-width: 600px;
  margin: 0px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.profile-page h1 {
  text-align: center;
  margin-bottom: 20px;
}

.profile-details {
  font-size: 14px;
}

.profile-details p {
  margin: 10px 0;
  font-size: 14px;
}


.profile-card {
  padding: 20px;
  text-align: center;
  background: linear-gradient(to bottom right, #ffffff, #4c9f8b);
  color: white;
  border-radius: 15px;
  margin: 10px 0px;
}



.avatar {
  width: 100px !important;
  height: 100px !important;
  margin: 10px auto;
  border: 3px solid white;
}


.stat-item {
  border: 2px solid white;
  width: 100%;
  background: #ffffff;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 10px 10px;
}

.stat-item h6{
  font-size: 16px;
  color: #428b79;
}

.match-summary {
  background: url('') no-repeat center center !important;
  background-size: cover !important;
  color: white !important;
  padding: 20px imp;
  border-radius: 10px;
}

.match-info {
  margin-top: 20px;
}

.summary-text {
  text-align: center;
  margin-top: 20px;
  font-size: 20px !important;
}

.summary-text .MuiTypography-h4 {
  margin-top: 10px;
}




@media (max-width: 600px) {
  .match-summary {
    margin: 10px;
    padding: 10px;
  }

  .summary-text {
    margin-top: 0px;
    text-align: left;
    font-size: 10px !important;
  }

  .mob-score {
    font-size: 1.5rem;
  }
}

.leadger-heading {
  height: 60px;
  background-color: #9b6191;
  color: #f9f9f9;
}

.notification-header {
  background-color: #4a4a4a;
  color: #fff;
  padding: 8px;
  display: ruby;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notification-text {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
  font-size: 16px;
  font-weight: 600;
  height: 31px;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.player-wrapper {
  position: relative;
  padding-top: 0.25%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.sassion-bet-table td {
  border: 2px solid rgb(255, 255, 255);
  border-width: 2px;
}

.sassion-bet-table table,
tr {
  border: 2px solid rgb(255, 255, 255);
  border-width: 2px;
  font-size: 12px !important;
}

.sassion-bet-table tbody,
th {
  padding: 0px;
}

.sassion-bet-table tbody td div {
  padding: 0px;
  margin: 0px;

}

.fancy-bet table tr td {
  border: 2px solid white;
  font-size: 12px;
  padding: 8px !important;
}

.fancy-bet table {
  background-color: #f2f2f2;
}


.fancy-bet-table {
  overflow-x: auto;
}

.fancy-bet tr th {
  padding: 0px 10px;
  background-color: #cda9c7;
  text-align: center !important;
  font-size: 14px;
}

.fancy-bet table tr {
  text-align: center;
  border-width: 1;
  border: 1px solid;
}

.loginlogo {
  display: flex;
  justify-content: center;
  align-items: center;
}


.loginlogo img {
  width: 150px;
}


.login-body {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.login-image {
  height: 100%;
  width: 100%;
  margin: auto;
}

.login-image img {
  width: 100%;
}

.tech_error_field {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

}

.technical_error {
  text-align: center;
  font-family: cursive;

}

.technical_error h1 {
  font-size: 10rem;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 10rem;
}

.technical_error h3 {
  font-size: 3rem;
  font-weight: 700;
}

.technical_error p {
  color: #BCC0C3;
}

.hh-tx {
  font-size: 14px;
  font-weight: bold;
}

.home-dashboard {

  background-color: #f1ecec;
}



.flip-clock {
  --fcc-flip-duration: 0.5s;
  /* transition duration when flip card */
  --fcc-spacing: 8px;
  /* space between unit times and separators */
  --fcc-digit-block-width: 40px;
  /* width of digit card */
  --fcc-digit-block-height: 60px;
  /* height of digit card, highly recommend in even number */
  --fcc-digit-block-radius: 5px;
  /* border radius of digit card */
  --fcc-digit-block-spacing: 5px;
  /* space between blocks in each unit of time */
  --fcc-digit-font-size: 30px;
  /* font size of digit */
  --fcc-digit-color: white;
  /* color of digit */
  --fcc-label-font-size: 10px;
  /* font size of label */
  --fcc-label-color: #ffffff;
  /* color of label */
  --fcc-background: black;
  /* background of digit card */
  --fcc-divider-color: white;
  /* color of divider */
  --fcc-divider-height: 1px;
  /* height of divider */
  --fcc-separator-size: 6px;
  /* size of colon */
  --fcc-separator-color: red;
  /* color of colon */
}

.w-100 {
  width: 100%;
}

.head-title {
  color: white;
  background-color: #009272;
  padding: 0.5rem;
  border-radius: 8px;
  font-weight: bold;
}

.table-head-bar {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
  justify-content: left;
  color: #212529;
}

.table-head-bar i {
  font-size: 20px;
  margin-right: 0.5rem;
  margin-top: 0.1rem;
  cursor: pointer;
}

.table-head-bar input {
  outline: none;
  width: 100%;
  border: none;
  font-size: 18px;
}

.table-head-bar h1 {
  border-right: 1px solid rgba(0, 0, 0, .12);
  margin-right: 1rem !important;
  padding-right: 1rem;
  font-size: 20px;
  margin-bottom: 0;
  white-space: nowrap;
}

.lanedane-container .items {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 0.5rem 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.75rem;
  height: 100px;
}

.lanedane-container .items span {
  font-size: 20px;
  font-weight: bold;
  color: white;

}

.lanedane-container .cmd {
  padding: 10px 0px;
}


@media(max-width:767px) {


  .lanedane-container .items {
      height: auto;
      border-radius: 8px;
  }

  .lanedane-container .items span {
      font-size: 14px;
  }

}

.textblue{
  color: blue;
}
.textred{
  color: red;
}
.textgrey{
  color: #555555;
}