
.match-summary {
    background: url('https://example.com/path/to/your/background-image.jpg') no-repeat center center;
    background-size: cover;
    color: white;
    padding: 20px;
    border-radius: 10px;
  }
  
  .match-info {
    margin-top: 20px;
  }
  
  .summary-text {
    text-align: center;
    margin-top: 20px;
  }
  
  .summary-text .MuiTypography-h4 {
    margin-top: 10px;
  }
  
.session-container{
  position: relative;
}
  

  .session-container .position-relative {
    position: relative;
  }
  

  
  .session-container .overlay i {
    pointer-events: none; /* Lock icon is non-clickable */
  }
  

   .session-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    pointer-events: none; 
  } 

.sassion-bet-table .back-box{
  background-color: #72bbef;
  border: 1 px solid rgb(236, 236, 236);
}
.sassion-bet-table .lay-box{
  background-color:#faa9ba ;
 
}

  /* #72bbef back color */
  /* #faa9ba lay color */



  /* ugfgegehgefg8yh */

  .session-odds-table {
    width: 100%;
    margin: 0px;
    background-color: #f08080;
  }
  
  .session-odds-table .rows {
    /* align-items: center;
    margin-bottom: 10px; */
    width: 100%;
    background-color: #f2f2f2;
    border-bottom: 1px solid #ccc;
  }
  
  .session-odds-table .header {
    background-color: #d8bfd8; /* Light purple */
    font-weight: bold;
    display: flex;
    height: 50px;
    
  }

  .session-odds-table .sassion-collection{
/* padding: 2px 0px; */
  }
  
  .session-odds-table .session, .not, .yes {
    flex: 1;
    text-align: center;
  }
  
  .session-odds-table .session {
    text-align: left;
    /* flex: 3; */
  }
  
  .not, .yes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 10px; */
  }
  .session{
    background-color: #e9e9e9;
  }
  
   .not {
    background-color: #87cefa; 
    background-color: #f08080;
    /* Light blue */
  }
  
  .yes {
    background-color: #f08080; /* Light red */
  }
  
  .session-title {
    font-size: 13px;
    font-weight: 600;
  }
  
  .session-limit {
    font-size: 10px;
  }
  
  .price {
    font-size: 13px;
    font-weight: 600;
  }
  
  .size {
    font-size: 10px;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
  }
  


/* fancy bet table  */



.nodata-img img{

  width: 100%;
  height: auto;

}
